import PageContainer from '@shared/components/page-parts/Container';
import PageHead from '@shared/components/page-parts/Head';
import PageContent from '@shared/components/page-parts/Content';
import TopNav from '@shared/components/top-nav/Nav';
import Breadcrumbs from '@shared/components/breadcrumbs';
import Dropdown from '@pages/statistics/modal/selector';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import ToneMappingTextField from '@pages/studio/ToneMappingTextField';
import RangeSlider from '@pages/scenes/child-details/LightDetails/RangeSlider';
import { FormControl, makeStyles, TextField } from '@material-ui/core';
import Paper from '@shared/components/paper';
import Button from '@shared/components/button';
import useActions from '@shared/hooks/useActions';
import { actions } from '@store/companies';
import { isAdmin } from '@shared/models';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';

const BREADCRUMBS_ITEMS = [{ name: 'Visualization Settings' }];

const useStyles = makeStyles({
  settingsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '40px',
    padding: '20px',
    width: '40%',
  },
  paper: {
    height: '400px',
  },
});

const VisualizationSettings = () => {
  const curCompany = useSelector((state) => state.app.company);
  const companies = useSelector((state) => state.companies.companies);
  const curUser = useSelector((state) => state.app.user);
  const [selectedCompany, setSelectedCompany] = useState(curCompany);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { updateCompany } = useActions(actions);
  const isSuperAdmin = isAdmin(curUser);

  const { control, formState: { errors: fieldsErrors }, handleSubmit, reset } = useForm();

  useEffect(() => {
    reset({
      name: selectedCompany?.name,
      toneMapping: selectedCompany?.toneMapping || 'CustomToneMapping',
      toneMappingExposure: selectedCompany?.toneMappingExposure || 1,
    });
  }, [selectedCompany, reset]);

  const classes = useStyles();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    setSnackbarOpen(true);
    updateCompany({ id: selectedCompany.id, company: data });
  });

  return (
    <PageContainer>
      <PageHead>
        <TopNav>
          <Breadcrumbs items={ BREADCRUMBS_ITEMS } />
          {isSuperAdmin ? (
            <Dropdown
              companies={ companies }
              onSelectCompany={ setSelectedCompany }
              curCompany={ curCompany }
            />
          ) : null}

        </TopNav>
      </PageHead>
      <PageContent>
        <Paper elevation={ 8 } className={ classes.paper }>
          <form noValidate onSubmit={ onSubmit } className={ classes.settingsContainer }>
            <FormControl fullWidth variant="outlined">
              <Controller
                name="toneMapping"
                control={ control }
                rules={ { required: 'Required' } }
                render={ ({ field }) => (
                  isSuperAdmin ? (
                    <ToneMappingTextField
                      { ...field }
                      label="Tone Mapping"
                      required
                    />
                  ) : (
                    <TextField disabled label="Tone Mapping" { ...field } />
                  )
                ) }
              />
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <Controller
                name="toneMappingExposure"
                control={ control }
                render={ ({ field }) => (
                  <RangeSlider
                    isDisabled={ !isSuperAdmin }
                    label="Tone Mapping Exposure"
                    min={ 0 }
                    max={ 10 }
                    displayValues={ false }
                    displayThumbValue
                    debounce={ 100 }
                    { ...field }
                    onChange={ ({ value }) => field.onChange(value) }
                  />
                ) }
              />
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={ !isSuperAdmin }
            >
              Submit
            </Button>
          </form>
          <Snackbar
            open={ snackbarOpen }
            autoHideDuration={ 3000 }
            onClose={ handleSnackbarClose }
          >
            <Alert
              severity="success"
              style={ {
                width: '100%',
                fontSize: '14px',
                padding: '5px',
                borderRadius: '8px',
              } }
            >
              Submitted
            </Alert>
          </Snackbar>
        </Paper>
      </PageContent>
    </PageContainer>
  );
};

export default VisualizationSettings;
