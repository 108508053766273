import withAuth from '@shared/components/route/withAuth';
import useActions from '@shared/hooks/useActions';
import { actions as sideMenuActions } from '@store/app/index';
import LimitsStats from '@pages/usage-limits-fields';
import { useSelector } from 'react-redux';
import { actions } from '@store/companies';
import { useEffect } from 'react';
import { isAdmin, isCollaborator } from '@shared/models';
import Home from '@pages/home';

const StatisticsLimit = withAuth(() => {
  const curUser = useSelector((state) => state.app.user);
  const { loadCompanies } = useActions(actions);
  const { showMenu } = useActions(sideMenuActions);

  useEffect(() => {
    showMenu();
    if (isAdmin(curUser)) {
      loadCompanies();
    }
  }, [loadCompanies, showMenu, curUser]);
  return <LimitsStats />;
});

export default StatisticsLimit;

