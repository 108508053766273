import PageContainer from '@shared/components/page-parts/Container';
import PageHead from '@shared/components/page-parts/Head';
import PageContent from '@shared/components/page-parts/Content';
import TopNav from '@shared/components/top-nav/Nav';
import Breadcrumbs from '@shared/components/breadcrumbs';
import Dropdown from '@pages/statistics/modal/selector';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { FormControl, makeStyles } from '@material-ui/core';
import Paper from '@shared/components/paper';
import Button from '@shared/components/button';
import useActions from '@shared/hooks/useActions';
import { actions } from '@store/companies';
import { isAdmin } from '@shared/models';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import DayTimeTextField from '@pages/relight-settings/DayTimeTextField';
import DeleteIcon from '@resources/icons/DeleteIcon';
import Control from '@shared/components/card-controls/Control';
import LightTextField from '@pages/relight-settings/LightTextField';
import { TextField } from '@shared/views/form-dialog';
import Checkbox from '@shared/components/checkbox';
import FormControlLabel from '@shared/components/form-control-label';
import StyleModeField from '@pages/relight-settings/StyleModeField';
import RestoreModeField from '@pages/relight-settings/RestoreModeField';

const BREADCRUMBS_ITEMS = [{ name: 'Relight Settings' }];

const useStyles = makeStyles({
  settingsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '40px',
    width: '100%',
    padding: '20px 0',
  },
  paper: {
    height: '100%',
    padding: '20px',
  },
  variant: {
    display: 'flex',
    gap: '40px',
    width: '100%',
  },
});

const defaultRelight = {
  items: [
    {
      prompt1: 'afternoon',
      prompt2: 'neutral light, soft shadows',
      styleMode: 'FOREGROUND_BACKGROUND',
      restoreMode: 'FOREGROUND',
      details: 0,
    },
    {
      prompt1: 'afternoon',
      prompt2: 'cold light, soft shadows',
      styleMode: 'FOREGROUND_BACKGROUND',
      restoreMode: 'FOREGROUND',
      details: 0,
    },
    {
      prompt1: 'night',
      prompt2: 'warm light, soft shadows',
      styleMode: 'FOREGROUND_BACKGROUND',
      restoreMode: 'FOREGROUND',
      details: 0,
    },
  ],
};

const VisualizationSettings = () => {
  const curCompany = useSelector((state) => state.app.company);
  const companies = useSelector((state) => state.companies.companies);
  const curUser = useSelector((state) => state.app.user);
  const [selectedCompany, setSelectedCompany] = useState(curCompany);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { updateCompany } = useActions(actions);
  const isSuperAdmin = isAdmin(curUser);

  const { control, formState, handleSubmit, reset } = useForm();

  const fieldArray = useFieldArray({
    control,
    name: 'relight.items',
  });

  const { fields, append, remove, replace } = fieldArray;

  useEffect(() => {
    const value = {
      name: selectedCompany?.name,
      relight: selectedCompany?.relight?.items?.length ? selectedCompany?.relight : defaultRelight,
    };
    reset(value);
    replace(value.relight.items);
  }, [selectedCompany, reset, replace]);


  const classes = useStyles();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    setSnackbarOpen(true);
    updateCompany({ id: selectedCompany.id, company: data });
  });

  return (
    <PageContainer>
      <PageHead>
        <TopNav>
          <Breadcrumbs items={ BREADCRUMBS_ITEMS } />
          {isSuperAdmin ? (
            <Dropdown
              companies={ companies }
              onSelectCompany={ setSelectedCompany }
              curCompany={ curCompany }
            />
          ) : null}

        </TopNav>
      </PageHead>
      <PageContent>
        <Paper elevation={ 8 } className={ classes.paper }>
          <Button
            variant="contained"
            color="primary"
            disabled={ fields.length > 2 }
            onClick={ () => {
              append({
                prompt1: 'Morning',
                prompt2: 'Warm Light',
                details: 0.8,
                styleMode: 'FOREGROUND',
                restoreMode: 'FOREGROUND',
              });
            } }
          >
            Add Variant
          </Button>
          <form noValidate onSubmit={ onSubmit } className={ classes.settingsContainer }>
            {
              fields.map((field, index) => {
                return (
                  <div className={ classes.variant } key={ field.id }>
                    <FormControl fullWidth variant="outlined">
                      <Controller
                        name={ `relight.items.${index}.prompt1` }
                        control={ control }
                        rules={ { required: 'Required' } }
                        render={ ({ field }) => (
                          <DayTimeTextField
                            { ...field }
                            label="Part of Day"
                            required
                          />
                        ) }
                      />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                      <Controller
                        name={ `relight.items.${index}.prompt2` }
                        control={ control }
                        rules={ { required: 'Required' } }
                        render={ ({ field }) => (
                          <LightTextField
                            { ...field }
                            label="Light Type"
                            required
                          />
                        ) }
                      />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                      <Controller
                        name={ `relight.items.${index}.styleMode` }
                        control={ control }
                        render={ ({ field }) => (
                          <StyleModeField
                            { ...field }
                            label="Style Mode"
                            required
                          />
                        ) }
                      />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                      <Controller
                        name={ `relight.items.${index}.restoreMode` }
                        control={ control }
                        render={ ({ field }) => (
                          <RestoreModeField
                            { ...field }
                            label="Restore Details Mode"
                            required
                          />
                        ) }
                      />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                      <Controller
                        name={ `relight.items.${index}.details` }
                        control={ control }
                        rules={ { required: 'Required' } }
                        render={ ({ field }) => (
                          <TextField
                            { ...field }
                            max={ 1 }
                            min={ 0 }
                            step={ 0.1 }
                            type="number"
                            label="Restore Details (0-1)"
                            required
                          />
                        ) }
                      />
                    </FormControl>
                    <Control onClick={ () => {
                      remove(index);
                      if (fields.length === 1) {
                        replace(defaultRelight.items);
                      }
                    } }
                    >
                      <DeleteIcon />
                    </Control>
                  </div>
                );
              })
            }
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </form>
          <Snackbar
            open={ snackbarOpen }
            autoHideDuration={ 3000 }
            onClose={ handleSnackbarClose }
          >
            <Alert
              severity="success"
              style={ {
                width: '100%',
                fontSize: '14px',
                padding: '5px',
                borderRadius: '8px',
              } }
            >
              Submitted
            </Alert>
          </Snackbar>
        </Paper>
      </PageContent>
    </PageContainer>
  );
};

export default VisualizationSettings;
