import { forwardRef, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Autocomplete from '@shared/components/autocomplete/Autocomplete';
import { TextField } from '@shared/views/form-dialog';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles({
  textFieldRoot: {
    "& > div.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      paddingRight: '9px',
      '& button': {
        order: 3,
      },
      '& > div.MuiAutocomplete-endAdornment': {
        position: 'relative',
        order: 2,
      },
    },
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& > img': {
      width: '40px',
      height: '40px',
    },
  },
});

const MaterialTextField = forwardRef(({
  name,
  label,
  onChange,
  onSelect,
  onLoad,
  value,
  required,
  disabled = false,
}, ref) => {
  const classes = useStyles();

  const { watch } = useFormContext();
  const options = useSelector((state) => state.materials.materials.items);

  const defaultValue = { name: '' };
  const watchedValue = watch(name);

  const handleChange = useCallback((_, data) => {
    if (data && data.id) {
      onChange(data);
      onSelect(data);
    } else {
      const selected = options.find((op) => op.name === data) || (value?.name === data ? value : defaultValue);
      onChange(selected);
      onSelect(selected);
    }
  }, [onChange, options, value, defaultValue]);

  const getOptionObject = useCallback((option) => ((typeof option === 'string' || option instanceof String)
    ? options.find((op) => op.id === option)
    : option
  ) || defaultValue, [options, defaultValue]);

  const getOptionLabel = useCallback((option) => (
    option ? (getOptionObject(option).name || '') : (option || '')
  ), [getOptionObject]);

  const getOptionSelected = useCallback((option, value) => (
    value ? option.id === getOptionObject(value).id : value
  ), [getOptionObject]);

  const handleBlur = useCallback((e) => {
    if (value.name !== e.target.value) {
      handleChange(e, e.target.value);
    }
  }, [value, handleChange]);

  return (
    <Autocomplete
      freeSolo
      disablePortal={ false }
      disabled={ disabled }
      options={ options }
      getOptionLabel={ getOptionLabel }
      getOptionSelected={ getOptionSelected }
      onChange={ handleChange }
      onBlur={ handleBlur }
      ref={ ref }
      value={ watchedValue ?? value }
      filterOptions={ (options, state) => {
        const sortedOptions = options.sort((a, b) => a.name.localeCompare(b.name));
        return state.inputValue.length > 0
          ? sortedOptions.filter(
            (item) => String(item.name)
              .toLowerCase()
              .includes(state.inputValue.toLowerCase()),
          )
          : sortedOptions.slice(0, 10);
      } }
      renderInput={ (params) => (
        <TextField
          { ...params }
          classes={ {
            root: classes.textFieldRoot,
          } }
          required={ required }
          label={ label }
          variant="outlined"
          InputProps={ {
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
              </>
            ),
          } }
        />
      ) }
      renderOption={ (props, { selected }) => (
        <div className={ classNames({ selected }, classes.option) }>
          <img alt={ props.name } src={ `${props.textures?.map?.url}?size=64` } />
          { props.name }
        </div>
      ) }
    />
  );
});

export default memo(MaterialTextField);
