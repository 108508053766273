import { combineReducers } from 'redux';
import { fork } from 'redux-saga/effects';
import appReducer from './app';
import appSaga from './app/sagas';
import companiesReducer from './companies';
import companiesSaga from './companies/sagas';
import interiorsReducer from './interiors/reducers';
import interiorsSaga from './interiors/sagas';
import modelsSaga from './models/sagas';
import modelsReducer from './models/reducers';
import scenesReducer from './scenes/reducers';
import scenesSaga from './scenes/sagas';
import usersReducer from './users';
import usersSaga from './users/sagas';
import materialsSaga from './materials/sagas';
import materialsReducer from './materials/reducers';
import productsReducer from './products/reducers';
import productsSaga from './products/sagas';
import studiosSaga from './studios/sagas';
import studiosReducer from './studios/reducers';
import attributeDefinitionsSaga from './attribute-definitions/sagas';
import attributeDefinitionsReducer from './attribute-definitions/reducers';
import stylingSaga from './styling/sagas';
import stylingReducer from './styling/reducers';
import groupsSaga from './material-groups/sagas';
import materialGroupsReducer from './material-groups';
import statsSaga from '@store/stats/sagas';
import statsReducer from '@store/stats/reducer';
import materialPresetsReducer from '@store/material-presets/reducers';
import materialPresetsSaga from './material-presets/sagas';

export const rootReducer = combineReducers({
  app: appReducer,
  companies: companiesReducer,
  interiors: interiorsReducer,
  models: modelsReducer,
  stats: statsReducer,
  scenes: scenesReducer,
  users: usersReducer,
  materials: materialsReducer,
  products: productsReducer,
  studios: studiosReducer,
  attributeDefinitions: attributeDefinitionsReducer,
  styling: stylingReducer,
  groups: materialGroupsReducer,
  materialPresets: materialPresetsReducer,
});

export function* rootSaga() {
  yield fork(appSaga);
  yield fork(companiesSaga);
  yield fork(interiorsSaga);
  yield fork(modelsSaga);
  yield fork(scenesSaga);
  yield fork(usersSaga);
  yield fork(materialsSaga);
  yield fork(productsSaga);
  yield fork(studiosSaga);
  yield fork(attributeDefinitionsSaga);
  yield fork(stylingSaga);
  yield fork(groupsSaga);
  yield fork(statsSaga);
  yield fork(materialPresetsSaga);
  // code after fork-effect
}

