import { useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { NavLink, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Collapse from '@material-ui/core/Collapse';

import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import InteriorsIcon from '@resources/icons/InteriorsIcon';
import ModelsIcon from '@resources/icons/ModelsIcon';
import ScenesIcon from '@resources/icons/ScenesIcon';
import CompaniesIcon from '@resources/icons/CompaniesIcon';
import UsersIcon from '@resources/icons/UsersIcon';
import ProductsIcon from '@resources/icons/ProductsIcon';
import MaterialsIcon from '@resources/icons/MaterialsIcon';
import AttributeDefinitionsIcon from '@resources/icons/AttributeDefinitionsIcon';
import VisualIcon from '@resources/icons/visual';

import { actions } from '@store/app';
import useActions from '@shared/hooks/useActions';
import { UserRole } from '@shared/models';
import Typography from '../typography';

import { ROUTES } from '@app/routes/routesUrls';
import { MENU } from '@shared/components/menu/constatnts';
import StatsIcon from '@resources/icons/StatsIcon';
import LimitsIcon from '@resources/icons/Limits';

const drawerWidth = 256;

const styles = (theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    wordBreak: 'break-all',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(5) + 1,
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    justifyContent: 'flex-end',
    padding: '0 8px',
    overflowX: 'hidden',
    borderRight: 'none',
    ...theme.mixins.toolbar,
  },
  navList: {
    padding: '15px 0 20px 35px',
  },
  navLink: {
    position: 'relative',
    display: 'block',
    marginBottom: theme.spacing(3.75),

    '&:last-child': {
      marginBottom: 0,
    },

    '&:hover': {
      '& span': {
        color: theme.palette.common.green,
      },
      '& path': {
        fill: theme.palette.common.green,
      },
      '& rect:not(.ignore)': {
        fill: theme.palette.common.green,
      },
      '& rect.ignore': {
        stroke: theme.palette.common.green,
      },
      '& div': {
        display: 'block',
      },
    },

    '&.active': {
      '& span': {
        color: theme.palette.common.green,
      },
      '& div': {
        '& path': {
          fill: theme.palette.common.green,
        },
        '& rect:not(.ignore)': {
          fill: theme.palette.common.green,
        },
        '& rect.ignore': {
          stroke: theme.palette.common.green,
        },
      },
    },
  },
  navTip: {
    display: 'none',
    width: 'max-content',
    position: 'fixed',
    left: theme.spacing(1.5),
    marginTop: theme.spacing(4.375),
    fontWeight: 'normal',
    fontSize: '1.2rem',
    lineHeight: '1rem',
    color: theme.palette.common.white,
    background: theme.palette.common.green,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 1.375),
    cursor: 'default',
    overflow: 'visible',
    zIndex: theme.zIndex.tooltip,

    '&:before': {
      position: 'absolute',
      top: theme.spacing(-0.625),
      left: theme.spacing(2.5),
      borderLeft: '.5rem solid transparent',
      borderRight: '.5rem solid transparent',
      borderTop: `.5rem solid ${theme.palette.common.green}`,
      content: '""',
      fontSize: 0,
      lineHeight: 0,
      marginLeft: theme.spacing(-0.625),
      width: theme.spacing(0),
      transform: 'rotate(180deg)',
    },
  },
  navItem: {
    display: 'flex',
    alignItems: 'center',
  },
  navImage: {
    display: 'block',
    marginRight: theme.spacing(1.9375),
    width: theme.spacing(2.75),
    height: theme.spacing(2.75),
  },
  navText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.01em',
    color: theme.palette.common.darkGrey,
  },
  navCopyright: {
    padding: '20px',
    marginTop: 'auto',
    fontWeight: '500',
    fontSize: '1.1rem',
    lineHeight: '1.6rem',
    letterSpacing: '0.01em',
    color: theme.palette.common.grey,
  },
  navBlock: {
    position: 'relative',
    padding: theme.spacing(2.5),
  },
  profileSettingsBtn: {
    transform: 'rotate(90deg)',
    padding: '.3rem !important',
    marginRight: '1.6rem !important',
    color: `${theme.palette.common.grey} !important`,
  },
  navSettingsLink: {
    padding: theme.spacing(2.5),
    paddingBottom: theme.spacing(0),
    position: 'relative',
    display: 'block',
    userSelect: 'none',

    '&:last-child': {
      marginBottom: 0,
    },

    '&:hover': {
      '& span': {
        color: theme.palette.common.green,
      },
      '& path': {
        fill: theme.palette.common.green,
      },
      '& rect:not(.ignore)': {
        fill: theme.palette.common.green,
      },
      '& rect.ignore': {
        stroke: theme.palette.common.green,
      },
      '& div': {
        display: 'block',
      },
    },

    '&.active': {
      '& span': {
        color: theme.palette.common.green,
      },
      '& div': {
        '& path': {
          fill: theme.palette.common.green,
        },
        '& rect:not(.ignore)': {
          fill: theme.palette.common.green,
        },
        '& rect.ignore': {
          stroke: theme.palette.common.green,
        },
      },
    },
  },
  navArrowDrop: {
    color: theme.palette.common.grey,
    fontSize: '2.4rem',
    height: theme.spacing(2.75),
    width: theme.spacing(2.75),
  },
});

const routes = [
  {
    name: 'Interiors',
    path: ROUTES.SCENES.ROOT,
    roles: [UserRole.ADMIN, UserRole.COMPANY_ADMIN, UserRole.COLLABORATOR],
    icon: <ScenesIcon />,
    layer: MENU.CMS,
  },
  {
    name: 'Backgrounds',
    path: ROUTES.INTERIORS,
    roles: [UserRole.ADMIN, UserRole.COMPANY_ADMIN, UserRole.COLLABORATOR],
    icon: <InteriorsIcon />,
    layer: MENU.CMS,
  },
  {
    name: 'Products',
    path: ROUTES.PRODUCTS.ROOT,
    roles: [UserRole.ADMIN, UserRole.COMPANY_ADMIN, UserRole.COLLABORATOR],
    icon: <ProductsIcon />,
    layer: MENU.CMS,
  },
  {
    name: 'Models',
    path: ROUTES.MODELS,
    roles: [UserRole.ADMIN, UserRole.COMPANY_ADMIN, UserRole.COLLABORATOR],
    icon: <ModelsIcon />,
    layer: MENU.CMS,
  },
  {
    name: 'Materials',
    path: ROUTES.MATERIALS,
    roles: [UserRole.ADMIN, UserRole.COMPANY_ADMIN, UserRole.COLLABORATOR],
    icon: <MaterialsIcon />,
    layer: MENU.CMS,
  },
  {
    name: 'Usage & Limits',
    path: ROUTES.STATISTICS,
    roles: [UserRole.ADMIN, UserRole.COMPANY_ADMIN, UserRole.COLLABORATOR],
    icon: <StatsIcon />,
    layer: MENU.USAGE_AND_LIMITS,
  },
  {
    name: 'Limits',
    path: ROUTES.STATISTICS_LIMITS,
    roles: [UserRole.ADMIN, UserRole.COMPANY_ADMIN, UserRole.COLLABORATOR],
    icon: <LimitsIcon />,
    layer: MENU.USAGE_AND_LIMITS,
  },
  {
    name: 'Companies',
    path: ROUTES.COMPANIES,
    roles: [UserRole.ADMIN, UserRole.COMPANY_ADMIN, UserRole.COLLABORATOR],
    icon: <CompaniesIcon />,
    layer: MENU.ACCOUNT,
  },
  {
    name: 'Users',
    path: ROUTES.USERS,
    roles: [UserRole.ADMIN, UserRole.COMPANY_ADMIN, UserRole.COLLABORATOR],
    icon: <UsersIcon />,
    layer: MENU.ACCOUNT,
  },
  {
    name: 'Attribute definitions',
    path: ROUTES.ATTRIBUTE_DEFINITIONS,
    roles: [UserRole.ADMIN, UserRole.COMPANY_ADMIN],
    icon: <AttributeDefinitionsIcon />,
    layer: MENU.CMS,
  },
  {
    name: 'Visualization Settings',
    path: ROUTES.VIZUALIZATION_SETTINGS,
    roles: [UserRole.ADMIN, UserRole.COMPANY_ADMIN],
    icon: <VisualIcon />,
    layer: MENU.CMS,
  },
  {
    name: 'Relight Settings',
    path: ROUTES.RELIGHT_SETTINGS,
    roles: [UserRole.ADMIN, UserRole.COMPANY_ADMIN],
    icon: <VisualIcon />,
    layer: MENU.CMS,
  },
  {
    name: 'Styling',
    path: ROUTES.STYLING,
    roles: [UserRole.ADMIN, UserRole.COMPANY_ADMIN],
    icon: <AttributeDefinitionsIcon />,
    layer: MENU.ACCOUNT,
  },
];

function AppMenu({ classes, onSelect }) {
  const { showMenu, logout } = useActions(actions);

  const [anchorEl, setAnchorEl] = useState();
  const [openSettings, setOpenSettings] = useState();
  const [openCMS, setCMS] = useState();
  const [openUsage, setUsage] = useState();
  const [openAccount, setAccount] = useState();

  const menuOpen = useSelector((state) => state.app.menuOpen);
  const user = useSelector((state) => state.app.user);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleNavLinkClick = () => {
    showMenu();
    onSelect();
  };

  const handleOpenSettings = () => {
    setOpenSettings((prevOpenSettings) => !prevOpenSettings);
  };
  const handleOpenCMS = () => {
    setCMS((prevOpenSettings) => !prevOpenSettings);
  };
  const handleOpenUsage = () => {
    setUsage((prevOpenSettings) => !prevOpenSettings);
  };
  const handleOpenAccount = () => {
    setAccount((prevOpenSettings) => !prevOpenSettings);
  };

  const createInitials = () => {
    if (user && user.firstname && user.lastname) {
      const initials = `${user.firstname.charAt(0)}${user.lastname.charAt(0)}`;
      return initials.toUpperCase();
    }
    return 'N/A';
  };

  const titleNameSurname = `${user.firstname} ${user.lastname}`;


  return (
    <Drawer
      variant="permanent"
      style={ { overflowY: 'hidden', boxShadow: '6px 0px 18px rgb(0 0 0 / 6%)', borderRight: 'none' } }
      className={ classNames(classes.drawer, {
        [classes.drawerOpen]: menuOpen,
        [classes.drawerClose]: !menuOpen,
      }) }
      classes={ {
        paper: classNames({
          [classes.drawerOpen]: menuOpen,
          [classes.drawerClose]: !menuOpen,
        }),
      } }
      open={ menuOpen }
    >
      <div>
        <div className={ classes.toolbar } />
        <div>
          <CardHeader
            className={ menuOpen ? 'profile' : 'profile profile_shifted' }
            avatar={ (
              <Avatar aria-label="recipe" className="profile__icon_source">
                {createInitials()}
              </Avatar>
              ) }
            action={ (
              <IconButton
                aria-label="settings"
                aria-owns={ open ? 'menu-appbar' : undefined }
                onClick={ handleMenu }
                className="profile__settings"
              >
                <MoreVertIcon />
              </IconButton>
              ) }
            title={ titleNameSurname }
            subheader={ user.email }
          />
          <Menu
            id="menu-appbar"
            anchorEl={ anchorEl }
            anchorOrigin={ {
              vertical: 'top',
              horizontal: 'right',
            } }
            transformOrigin={ {
              vertical: 'top',
              horizontal: 'right',
            } }
            open={ open }
            onClose={ handleClose }
          >
            <Link to={ ROUTES.PROFILE } style={ { textDecoration: 'none', color: 'inherit', fontSize: 'inherit' } }>
              <MenuItem onClick={ handleClose }>
                Profile
              </MenuItem>
            </Link>
            <MenuItem onClick={ logout }>Exit/Log Out</MenuItem>
          </Menu>

        </div>
        <ul className={ classes.navSettingsLink } onClick={ handleOpenCMS }>
          <li className={ classes.navItem }>
            <div className={ classes.navImage }>
              {openCMS ? <ArrowDropUpIcon className={ classes.navArrowDrop } />
                : <ArrowDropDownIcon className={ classes.navArrowDrop } />}
            </div>
            <Typography
              className={ classes.navText }
              component="span"
            >
              CMS
            </Typography>
          </li>
        </ul>
        <Collapse in={ openCMS } timeout="auto" unmountOnExit>
          <ul className={ classes.navList }>
            {routes
              .filter((route) => route.layer === MENU.CMS)
              .map((route) => (
                route.roles.includes(user.role)
                  ? (
                    <NavLink
                      className={ classes.navLink }
                      key={ route.path }
                      to={ route.path }
                      onClick={ handleNavLinkClick }
                    >
                      {!menuOpen && (
                      <div className={ classes.navTip }>{route.name}</div>
                      )}
                      <li className={ classes.navItem }>
                        <div className={ classes.navImage }>{route.icon}</div>
                        {menuOpen && (
                        <Typography
                          className={ classes.navText }
                          component="span"
                        >
                          {route.name}
                        </Typography>
                        )}
                      </li>
                    </NavLink>
                  )
                  : null
              ))}
          </ul>
        </Collapse>
        <ul className={ classes.navSettingsLink } onClick={ handleOpenUsage }>
          <li className={ classes.navItem }>
            <div className={ classes.navImage }>
              {openUsage ? <ArrowDropUpIcon className={ classes.navArrowDrop } />
                : <ArrowDropDownIcon className={ classes.navArrowDrop } />}
            </div>
            <Typography
              className={ classes.navText }
              component="span"
            >
              Usage & Limits
            </Typography>
          </li>
        </ul>
        <Collapse in={ openUsage } timeout="auto" unmountOnExit>
          <ul className={ classes.navList }>
            {routes
              .filter((route) => route.layer === MENU.USAGE_AND_LIMITS)
              .map((route) => (
                route.roles.includes(user.role)
                  ? (
                    <NavLink
                      className={ classes.navLink }
                      key={ route.path }
                      to={ route.path }
                      onClick={ handleNavLinkClick }
                    >
                      {!menuOpen && (
                      <div className={ classes.navTip }>{route.name}</div>
                      )}
                      <li className={ classes.navItem }>
                        <div className={ classes.navImage }>{route.icon}</div>
                        {menuOpen && (
                        <Typography
                          className={ classes.navText }
                          component="span"
                        >
                          {route.name}
                        </Typography>
                        )}
                      </li>
                    </NavLink>
                  )
                  : null
              ))}
          </ul>
        </Collapse>
        <ul className={ classes.navSettingsLink } onClick={ handleOpenAccount }>
          <li className={ classes.navItem }>
            <div className={ classes.navImage }>
              {openAccount ? <ArrowDropUpIcon className={ classes.navArrowDrop } />
                : <ArrowDropDownIcon className={ classes.navArrowDrop } />}
            </div>
            <Typography
              className={ classes.navText }
              component="span"
            >
              Account
            </Typography>
          </li>
        </ul>
        <Collapse in={ openAccount } timeout="auto" unmountOnExit>
          <ul className={ classes.navList }>
            {routes
              .filter((route) => route.layer === MENU.ACCOUNT)
              .map((route) => (
                route.roles.includes(user.role)
                  ? (
                    <NavLink
                      className={ classes.navLink }
                      key={ route.path }
                      to={ route.path }
                      onClick={ handleNavLinkClick }
                    >
                      {!menuOpen && (
                      <div className={ classes.navTip }>{route.name}</div>
                      )}
                      <li className={ classes.navItem }>
                        <div className={ classes.navImage }>{route.icon}</div>
                        {menuOpen && (
                        <Typography
                          className={ classes.navText }
                          component="span"
                        >
                          {route.name}
                        </Typography>
                        )}
                      </li>
                    </NavLink>
                  )
                  : null
              ))}
          </ul>
        </Collapse>
      </div>
      {menuOpen && (
      <div className={ classes.navCopyright }>© {new Date().getFullYear()} Zolak</div>
      )}
    </Drawer>
  );
}

export default (withStyles(styles, { withTheme: true })(AppMenu));
